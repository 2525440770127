import React from 'react'

const NoPages = () => {
  return (
    <div>
      No pages
    </div>
  )
}

export default NoPages
